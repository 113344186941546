import React, { useState, useEffect } from 'react';
import { Button, Col, Divider, Drawer, Row } from 'antd';
import { BackButtonLayout } from '../../../../../containers/BackButtonLayout';

import fish from '../../sliders/assets/image/SBFish.png';
import arrowDown from '../../sliders/assets/image/PolygonDown.svg';
import arrowRight from '../../sliders/assets/image/PolygonRight.svg';
import windArrow from '../../sliders/assets/image/Arrow-direction.svg';
import moonPhaseNew from '../../sliders/assets/image/Moons/New Moon.png';
import moonPhase2 from '../../sliders/assets/image/Moons/Waning Crescent.png';
import moonPhase3 from '../../sliders/assets/image/Moons/First Quarter.png';
import moonPhase4 from '../../sliders/assets/image/Moons/Waxing Gibbous.png';
import moonPhase5 from '../../sliders/assets/image/Moons/Full Moon.png';
import moonPhase6 from '../../sliders/assets/image/Moons/Waning Gibbous.png';
import moonPhase7 from '../../sliders/assets/image/Moons/Third Quarter.png';
import moonPhase8 from '../../sliders/assets/image/Moons/Waning Crescent.png';

import noImage from '../../../../common/assets/images/no-photo.jpg';
import axios from 'axios';

import SvgLeftChevron from '../../../../../assets/images/LeftChevron';
import { ImageSlider } from '../index';
import { useFlags } from 'flagsmith/react';

import moment from 'moment';
import { Collapse } from 'react-collapse';
import { useGetUserHasSubscriptionQuery } from '../../../../user_profile/userProfileSlice';
import PartlyCloudyDay from '../assets/image/partly-cloudy-day.png';
import PartlyCloudyNight from '../assets/image/partly-cloudy-night.png';
import ThunderRain from '../assets/image/thunder-rain.png';
import Snow from '../assets/image/snow.png';
import ShowersNight from '../assets/image/showers-night.png';
import Cloudy from '../assets/image/cloudy.png';
import Cloud from '../assets/image/cloud.png';
import Rain from '../assets/image/rain.png';
import wind from '../assets/image/wind.png';
import clearDay from '../assets/image/clear-day.png';
import clearNight from '../assets/image/clear-night.png';

function checkIfUserLoggedIn(setIsLoggedIn) {
  const axios_config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  axios
    .get('auth', axios_config)
    .then((res) => {
      if (res.status === 200) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    })
    .catch(() => {
      setIsLoggedIn(false);
    });
}

const CatchReportsDrawer = ({
  drawerOpen,
  setIsDrawerOpen,
  catchDetails,
  isFromProfile = false,
}) => {
  const [infoOpen, setInfoOpen] = useState(false);
  const toggleInfoSection = () => {
    setInfoOpen((prevState) => !prevState);
  };
  const { data: userHasSubscription } = useGetUserHasSubscriptionQuery();
  const {
    sb_pro: { enabled: sbProEnabled },
    gamification_ui: { enabled: progressEnabled },
  } = useFlags(['sb_pro', 'gamification_ui']);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    checkIfUserLoggedIn(setIsLoggedIn);
  }, [setIsLoggedIn]);

  let weatherIcon = '';

  if (
    [
      'thunder-rain',
      'thunder-showers-rain',
      'thunder-showers-day',
      'thunder-showers-night',
    ].includes(catchDetails?.weather?.icon_key)
  ) {
    weatherIcon = ThunderRain;
  } else if (
    ['snow', 'snow-showers-day', 'snow-showers-night'].includes(
      catchDetails?.weather?.icon_key
    )
  ) {
    weatherIcon = Snow;
  } else if (
    ['showers-day', 'showers-night'].includes(catchDetails?.weather?.icon_key)
  ) {
    weatherIcon = ShowersNight;
  } else if (['cloudy', 'fog'].includes(catchDetails?.weather?.icon_key)) {
    weatherIcon = Cloudy;
  } else if (['partly-cloudy-day'].includes(catchDetails?.weather?.icon_key)) {
    weatherIcon = PartlyCloudyDay;
  } else if (
    ['partly-cloudy-night'].includes(catchDetails?.weather?.icon_key)
  ) {
    weatherIcon = PartlyCloudyNight;
  } else if (catchDetails?.weather?.icon_key === 'rain') {
    weatherIcon = Rain;
  } else if (catchDetails?.weather?.icon_key === 'wind') {
    weatherIcon = wind;
  } else if (catchDetails?.weather?.icon_key === 'clear-day') {
    weatherIcon = clearDay;
  } else if (catchDetails?.weather?.icon_key === 'clear-night') {
    weatherIcon = clearNight;
  }

  let moonPhase = catchDetails?.weather?.moon_phase;
  let phase = '';
  let stage = '';
  let icon = null;
  if (moonPhase == 0) {
    phase = 'New moon';
    stage = 'One';
    icon = moonPhaseNew;
  } else if (moonPhase > 0 && moonPhase < 0.25) {
    phase = 'Waxing crescent';
    stage = 'Two';
    icon = moonPhase2;
  } else if (moonPhase == 0.25) {
    phase = 'First quarter';
    stage = 'Three';
    icon = moonPhase3;
  } else if (moonPhase > 0.25 && moonPhase < 0.5) {
    phase = 'Waxing gibbous';
    stage = 'Four';
    icon = moonPhase4;
  } else if (moonPhase == 0.5) {
    phase = 'Full moon';
    stage = 'Five';
    icon = moonPhase5;
  } else if (moonPhase > 0.5 && moonPhase < 0.75) {
    phase = 'Waning gibbous';
    stage = 'Six';
    icon = moonPhase6;
  } else if (moonPhase == 0.75) {
    phase = 'Last quarter';
    stage = 'Seven';
    icon = moonPhase7;
  } else if (moonPhase > 0.75 && moonPhase <= 1) {
    phase = 'Waning crescent';
    stage = 'Eight';
    icon = moonPhase8;
  } else {
    phase = 'Invalid moon phase';
    stage = 'Invalid';
    icon = '';
  }
  return (
    <Drawer
      title={null}
      footer={null}
      closeable={false}
      maskCloseable={false}
      className=' show_catch_report__drawer'
      open={drawerOpen}
      onClose={() => {
        setInfoOpen(false);
        setIsDrawerOpen(false);
      }}
    >
      <div className='pd-10 justify-between row-center'>
        <Button
          className='profile_layout sb-blue-bg-100  center pd-6'
          style={{ display: 'flex', justifyContent: 'center' }}
          shape='circle'
          icon={<SvgLeftChevron style={{ fill: '#062640' }} />}
          size='large'
          onClick={() => {
            setInfoOpen(false);
            setIsDrawerOpen(false);
          }}
        />
        <img src={fish} alt='sb-icon' width={44} height={20} />
      </div>
      <div style={{ height: '1rem' }} className='sb-blue-bg-800  mg-auto' />
      <div style={{ height: '1rem' }} className='sb-blue-bg-700  mg-auto' />
      <div style={{ height: '20rem' }} className='sb-blue-bg-800  mg-auto'>
        <div style={{ height: '19rem' }} className='sb-blue-bg-700  mg-auto'>
          <ImageSlider
            height='18rem'
            hideImageCount
            images={
              catchDetails?.image_upload
                ? [{ src: catchDetails.image_upload, alt: 'catch-image' }]
                : [{ src: noImage, alt: 'catch-image' }]
            }
            openOnClick
          />
        </div>
      </div>
      <div className='pd-20'>
        <div>
          <Row className='mg-top-20 gSemiBold'>
            <Col span={24} className='mg-bottom-20'>
              <Row justify='space-between'>
                <Col className='gSemiBold fs-24 sb-blue-100'>Catch Report</Col>
                {sbProEnabled && userHasSubscription && (
                  <Col className='gSemiBold fs-20 br20 sb-blue-bg-100 px-20 fr row-center font-montserrat'>
                    PRO
                  </Col>
                )}
              </Row>
            </Col>
            <Row
              gutter={[8, 8]}
              className='w-full  mg-bottom-10 catch-details'
              style={{ margin: 'auto' }}
            >
              <Col span={8}>
                <div className='sb-blue-bg-700 center br15 pd-10 h-full'>
                  <div className='sb-blue-300 fs-12 gRegular txt-center'>
                    Angler:
                  </div>
                  <div className='gSemiBold info-text txt-center sb-blue-100 center h-full justify-center'>
                    {catchDetails?.name?.toUpperCase()}
                  </div>
                </div>
              </Col>
              <Col span={8}>
                <div className='sb-blue-bg-700 center br15 pd-10 h-full'>
                  <div className='sb-blue-300 fs-12 gRegular txt-center'>
                    Species:
                  </div>
                  <div className='gSemiBold info-text txt-center sb-blue-100 center h-full justify-center'>
                    {catchDetails?.fish_species?.toUpperCase()}
                  </div>
                </div>
              </Col>
              <Col span={8}>
                <div className='sb-blue-bg-700 center br15 pd-10 h-full'>
                  <div className='sb-blue-300 fs-12 gRegular txt-center'>
                    Weights:
                  </div>
                  <div className='gSemiBold info-text txt-center sb-blue-100 center h-full uppercase justify-center'>
                    {`${catchDetails?.fish_weight_lb}lb ${catchDetails?.fish_weight_oz}oz`}
                  </div>
                </div>
              </Col>
              <Col span={8}>
                <div className='sb-blue-bg-700 center br15 pd-10 h-full'>
                  <div className='sb-blue-300 fs-12 gRegular txt-center'>
                    Fishery:
                  </div>
                  <div className='gSemiBold info-text txt-center sb-blue-100 center h-full justify-center'>
                    {catchDetails?.fishery?.toUpperCase()}
                  </div>
                </div>
              </Col>
              <Col span={8}>
                <div className='sb-blue-bg-700 center br15 pd-10 h-full'>
                  {' '}
                  <div className='sb-blue-300 fs-12 gRegular txt-center'>
                    Lake:
                  </div>
                  <div className='gSemiBold info-text txt-center sb-blue-100 center h-full justify-center'>
                    {catchDetails?.lake?.toUpperCase()}
                  </div>
                </div>
              </Col>
              <Col span={8}>
                <div className='sb-blue-bg-700 center br15 pd-10 h-full'>
                  <div className='sb-blue-300 fs-12 gRegular txt-center'>
                    Date & Time:
                  </div>
                  <div className='gSemiBold info-text txt-center sb-blue-100 center uppercase justify-center h-full'>
                    {moment(catchDetails?.fish_caught_date).format(
                      'DD/MM/YYYY HH:mm'
                    )}
                  </div>
                </div>
              </Col>
              {(catchDetails?.hooklink_material_used ||
                catchDetails?.rig ||
                catchDetails?.hook_used ||
                catchDetails?.bait ||
                catchDetails?.baiting_tactic ||
                catchDetails?.fish_name ||
                catchDetails?.additional_capture_information ||
                catchDetails?.additional_fish_details) && (
                <Col span={24}>
                  <div className='sb-blue-bg-700  br15 pd-10 h-full'>
                    <div
                      className='row-center justify-between cp'
                      onClick={toggleInfoSection}
                    >
                      <div className='sb-blue-300 fs-12 gRegular txt-center'>
                        Additional Details
                      </div>
                      <div className='gSemiBold fs-18 txt-center sb-blue-100 center uppercase justify-center h-full'>
                        {infoOpen ? (
                          <img src={arrowDown} alt='arrow' />
                        ) : (
                          <img src={arrowRight} alt='arrow' />
                        )}
                      </div>
                    </div>
                    <div>
                      <Collapse isOpened={infoOpen}>
                        <div className='mg-top-20'>
                          {catchDetails?.hooklink_material_used && (
                            <div className='mg-bottom-15'>
                              <div className='sb-blue-200 fs-12 gRegular'>
                                Hooklink:
                              </div>
                              <div className='sb-blue-100 fs-18 gSemiBold'>
                                {catchDetails?.hooklink_material_used}
                              </div>
                            </div>
                          )}
                          {catchDetails?.rig && (
                            <div className='mg-bottom-15'>
                              <div className='sb-blue-200 fs-12 gRegular'>
                                Rig:
                              </div>
                              <div className='sb-blue-100 fs-18 gSemiBold'>
                                {catchDetails?.rig}
                              </div>
                            </div>
                          )}
                          {catchDetails?.hook_used && (
                            <div className='mg-bottom-15'>
                              <div className='sb-blue-200 fs-12 gRegular'>
                                Hook:
                              </div>
                              <div className='sb-blue-100 fs-18 gSemiBold'>
                                {catchDetails?.hook_used}
                              </div>
                            </div>
                          )}
                          {catchDetails?.bait && (
                            <div className='mg-bottom-15'>
                              <div className='sb-blue-200 fs-12 gRegular'>
                                Bait:
                              </div>
                              <div className='sb-blue-100 fs-18 gSemiBold'>
                                {catchDetails?.bait}
                              </div>
                            </div>
                          )}
                          {catchDetails?.baiting_tactic && (
                            <div className='mg-bottom-15'>
                              <div className='sb-blue-200 fs-12 gRegular'>
                                Baiting Tactics:
                              </div>
                              <div className='sb-blue-100 fs-18 gSemiBold'>
                                {catchDetails?.baiting_tactic}
                              </div>
                            </div>
                          )}
                          {catchDetails?.fish_name && (
                            <div className='mg-bottom-15'>
                              <div className='sb-blue-200 fs-12 gRegular'>
                                Fish Name:
                              </div>
                              <div className='sb-blue-100 fs-18 gSemiBold'>
                                {catchDetails?.fish_name}
                              </div>
                            </div>
                          )}
                          {catchDetails?.additional_capture_information && (
                            <div className='mg-bottom-15'>
                              <div className='sb-blue-200 fs-12 gRegular'>
                                Additional Capture Details:
                              </div>
                              <div className='sb-blue-100 fs-18 gSemiBold'>
                                {catchDetails?.additional_capture_information}
                              </div>
                            </div>
                          )}
                          {catchDetails?.additional_fish_details && (
                            <div className='mg-bottom-15'>
                              <div className='sb-blue-200 fs-12 gRegular'>
                                Additional Fish Details:
                              </div>
                              <div className='sb-blue-100 fs-18 gSemiBold'>
                                {catchDetails?.additional_fish_details}
                              </div>
                            </div>
                          )}
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
            <div className='row-center w-full mg-bottom-10 justify-center gSemiBold fs-24 sb-blue-100'>
              Bite Conditions™
            </div>
            <Row
              gutter={[8, 12]}
              className='w-full  mg-bottom-10'
              style={{ margin: 'auto' }}
            >
              <Col span={12}>
                <div
                  className='sb-blue-bg-700 center br30 pd-10 h-full'
                  style={{ minHeight: 180 }}
                >
                  <div className='sb-blue-300 fs-12 uppercase gRegular txt-center'>
                    Weather
                  </div>
                  <div
                    className='gSemiBold fs-18 txt-center sb-blue-100 py-10 flex-wrap center h-full justify-center'
                    style={{ flexDirection: 'row', gap: '12px' }}
                  >
                    {weatherIcon && (
                      <img src={weatherIcon} alt='weather-icon' height={70} />
                    )}
                    <span className='sb-blue-300 fs-12  gRegular txt-center'>
                      {catchDetails?.weather?.weather}
                    </span>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div
                  className='sb-blue-bg-700 center br30 pd-10 h-full'
                  style={{ minHeight: 180 }}
                >
                  <div className='sb-blue-300 fs-12 uppercase gRegular txt-center'>
                    temperature
                  </div>
                  <div className='gSemiBold fs-18 txt-center sb-blue-100 py-10 center h-full justify-center'>
                    <div className='sb-blue-100 fs-3em gSemiBold lh-1'>
                      {catchDetails?.weather?.temperature}°
                    </div>
                  </div>
                  <div className='sb-blue-300 fs-12   gRegular txt-center'>
                    Celcius
                  </div>
                </div>
              </Col>

              {(catchDetails?.user_subscription_level == 'Pro' ||
                isFromProfile) && (
                <div className='w-full' style={{ position: 'relative' }}>
                  {isFromProfile && sbProEnabled && !userHasSubscription && (
                    <div className='backdrop-overlay' />
                  )}
                  {isFromProfile && sbProEnabled && !userHasSubscription && (
                    <div className='backdrop-overlay-text'>
                      <div>
                        To unlock the full Bite Conditions™ join swimbooker+
                        today
                      </div>
                      <button
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_SBPLUS_URL}/sign-up`,
                            '_blank'
                          )
                        }
                      >
                        UNLOCK NOW
                      </button>
                    </div>
                  )}
                  <Row
                    gutter={[8, 12]}
                    className='w-full mg-bottom-10'
                    style={{ margin: 0 }}
                  >
                    <Col span={12}>
                      <div
                        className='sb-blue-bg-700 center br30 pd-10 h-full'
                        style={{ minHeight: '180px' }}
                      >
                        <div className='sb-blue-300 fs-12 uppercase gRegular txt-center'>
                          cloud cover
                        </div>
                        <div
                          className='h-full w-full'
                          style={{ position: 'relative' }}
                        >
                          <img
                            style={{
                              position: 'absolute',
                              maxWidth: '100%',
                              left: '50%',
                              transform: 'translate(-50%)',
                            }}
                            src={Cloud}
                            alt='cloud'
                            height={62}
                            width={150}
                          />
                          <div
                            className='gSemiBold fs-18 txt-center sb-blue-100 center h-full  justify-end'
                            style={{ position: 'relative', zIndex: 2 }}
                          >
                            <div
                              className='sb-blue-100 cloud-cover gSemiBold lh-1 mg-bottom-15 '
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <span>{catchDetails?.weather?.cloud_cover}</span>
                            </div>
                            <div className='sb-blue-300 fs-12  gRegular txt-center'>
                              Percent
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div
                        className='sb-blue-bg-700 center br30 pd-10 h-full'
                        style={{ minHeight: 180 }}
                      >
                        <div className='sb-blue-300 fs-12 uppercase gRegular txt-center'>
                          pressure
                        </div>
                        <div className='gSemiBold fs-18 txt-center sb-blue-100 py-10 center h-full justify-center'>
                          <div className='sb-blue-100 pressure-text gSemiBold lh-1'>
                            {catchDetails?.weather?.pressure}
                          </div>
                        </div>
                        <div className='sb-blue-300 fs-12   gRegular txt-center'>
                          mbar
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    gutter={[0, 12]}
                    className='w-full'
                    style={{ padding: '0 4px', margin: '0 !important' }}
                  >
                    <Col span={24}>
                      <div className='sb-blue-bg-700  center br30 pd-10 h-full catch-report-large-box'>
                        <div className='sb-blue-300 fs-12 uppercase gRegular txt-center mg-bottom-15'>
                          Wind
                        </div>
                        <Row className='gSemiBold fs-18  sb-blue-100 center detail-box  justify-between w-full  h-full'>
                          <Col xs={11} sm={15}>
                            <div className='row-center'>
                              <div className=' sb-blue-100 gSemiBold mg-right-15 large-text'>
                                {catchDetails?.weather?.wind_speed}
                              </div>
                              <div className=''>
                                <div className='fs-12 gSemiBold sb-blue-300'>
                                  MPH
                                </div>
                                <div className='fs-12 gSemiBold sb-blue-100'>
                                  Wind
                                </div>
                              </div>
                            </div>
                            <Divider
                              style={{ background: 'rgba(138, 188, 229, 0.2)' }}
                              className='mg-top-10 mg-bottom-10'
                            />
                            <div className='row-center mg-bottom-10'>
                              <div className=' sb-blue-100 gSemiBold mg-right-15 large-text'>
                                {catchDetails?.weather?.wind_gust_speed}
                              </div>
                              <div className=''>
                                <div className='fs-12 gSemiBold sb-blue-300'>
                                  MPH
                                </div>
                                <div className='fs-12 gSemiBold sb-blue-100'>
                                  Gusts
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} sm={6} className='justify-center'>
                            <div className='wind-compass-container'>
                              <div className='direction gBold sb-blue-bg-700'>
                                {catchDetails?.weather?.wind_direction}
                              </div>
                              <img
                                src={windArrow}
                                className='arrow'
                                alt='wind-direction'
                                style={{
                                  transform: `rotate(${catchDetails?.weather?.wind_direction_angle}deg)`,
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className='sb-blue-bg-700 center  br30 pd-10 h-full'>
                        <div className='sb-blue-300 fs-12 uppercase gRegular txt-center mg-bottom-25'>
                          MOON PHASE
                        </div>
                        <Row className='gSemiBold fs-18  sb-blue-100 center detail-box  justify-between w-full  h-full'>
                          <Col xs={11} sm={15}>
                            <div className='row-center justify-between'>
                              <div className='gSemiBold sb-blue-100 '>
                                Phase
                              </div>
                              <div className='gSemibold fs-12 sb-blue-300 txt-right'>
                                {phase}
                              </div>
                            </div>
                            <Divider
                              style={{ background: 'rgba(138, 188, 229, 0.2)' }}
                              className='mg-top-10 mg-bottom-10'
                            />
                            <div className='row-center justify-between'>
                              <div className='gSemiBold sb-blue-100 '>
                                Stage
                              </div>
                              <div className='gSemibold mg-left-5 fs-12 sb-blue-300 txt-right'>
                                {stage}
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} sm={6} className='justify-center'>
                            <img src={icon} width={115} alt='moon-phase' />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </Row>
          </Row>
        </div>
      </div>
    </Drawer>
  );
};

export default CatchReportsDrawer;
