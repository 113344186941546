import { configureStore } from '@reduxjs/toolkit';
import userReducer from './User/Reducer';
import menuReducer from './Menu/Reducer';
import adminReducer from './Admin/Reducer';
import eventAdminReducer from './EventsAdmin/Reducer';
import { apiSlice } from './apiSlice';
import { proApiSlice } from './proApiSlice';
import { eventsApiSlice } from './eventApiSlice';

export const setupStore = (preloadedState = {}) =>
  configureStore({
    reducer: {
      User: userReducer,
      Menu: menuReducer,
      Admin: adminReducer,
      EventAdmin: eventAdminReducer,
      [apiSlice.reducerPath]: apiSlice.reducer,
      [proApiSlice.reducerPath]: proApiSlice.reducer,
      [eventsApiSlice.reducerPath]: eventsApiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(apiSlice.middleware)
        .concat(proApiSlice.middleware)
        .concat(eventsApiSlice.middleware),
    preloadedState,
  });
