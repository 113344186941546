import * as React from 'react';
import HeaderMenu from './components/menu/HeaderMenu';
import { Col, Layout, Row } from 'antd';
import SearchBox from './SearchBox';
import { Link, useHistory } from 'react-router-dom';

import roundedLogo from '../common/assets/images/logo_rounded 2.svg';
import nameLogo from '../common/assets/images/swimbooker_logo_no_icon 1.svg';
import { useRef } from 'react';
import { useEffect } from 'react';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
const { Header: AntDHeader } = Layout;

function Header({
  useLogo = false,
  headerExpandClass,
  isLoggedInState,
  loadingState,
  triggerRedirect,
  usesFixedMobileView = false,
  hideOnMobile = false,
  useMaxWidth = false,
  isUserProfile = false,
  isHome = false,
  isEventSignUp = false,
  isArticlePage = false,
}) {
  const history = useHistory();

  const { scrollY } = useViewportScroll();
  const translateYup = useTransform(scrollY, [9, 10], [-120, 0]);
  const translateYdown = useTransform(scrollY, [9, 10], [0, -120]);

  return isHome ? (
    <>
      <motion.div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
          transition: 'all .05s ease',
          translateY: translateYdown, // Animate translateY based on scroll
        }}
      >
        <AntDHeader
          id={'fixed-header'}
          className={
            headerExpandClass +
            ' top-header initial-header' +
            (useMaxWidth ? ' main_container_padding ' : '') +
            (hideOnMobile ? ' header-md ' : '')
          }
          // ref={WrapperRef}
        >
          <Row
            justify='space-between'
            className={`desktop-header ${
              useMaxWidth ? 'main_container_padding__content ' : ''
            }`}
          >
            <Col
              // id='sb-header-logo-col'
              className='gutter-row sb-align-left header-links'
              xs={0}
              sm={12}
              lg={17}
              xl={14}
            >
              <div className='row-center justify-between'>
                <div
                  className='cp row-center'
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  <img className='mg-right-15' src={roundedLogo} />
                  <div
                    className={`gSemiBold sb-blue fs-18 ${
                      isArticlePage ? 'sb-black' : 'white'
                    } row-center`}
                  >
                    swim<span className=''>booker</span>
                  </div>
                </div>
                <Link
                  className={`fs-18 gMedium cp ${
                    isArticlePage ? 'sb-black' : 'white'
                  }`}
                  to={'/search'}
                >
                  Find Venues
                </Link>
                <Link
                  className={`fs-18 gMedium cp ${
                    isArticlePage ? 'sb-black' : 'white'
                  }`}
                  to={'/about'}
                >
                  About Us
                </Link>
                <Link
                  className={`fs-18 gMedium cp ${
                    isArticlePage ? 'sb-black' : 'white'
                  }`}
                  to={'/contact'}
                >
                  Contact Us
                </Link>

                <Link
                  className={`fs-18 gMedium cp ${
                    isArticlePage ? 'sb-black' : 'white'
                  }`}
                  to={'/articles'}
                >
                  News
                </Link>

                <Link
                  className={`fs-18 gMedium cp ${
                    isArticlePage ? 'sb-black' : 'white'
                  }`}
                  to={'/admin/login'}
                >
                  Fishery Login
                </Link>
              </div>
            </Col>

            <Col
              className='gutter-row sb-align-right'
              id='sb-header-menu-col'
              xs={24}
              sm={12}
              lg={6}
            >
              <div className='row-center justify-end'>
                <Link
                  className={`fs-18 gBold ${
                    isArticlePage ? 'sb-blue-600' : 'white'
                  } mg-right-20`}
                  to='/swimbooker-fishery-manager'
                >
                  List your lakes
                </Link>
                <HeaderMenu
                  usesFixedMobileView={usesFixedMobileView}
                  isLoggedInState={isLoggedInState}
                  loadingState={loadingState}
                  triggerRedirect={triggerRedirect}
                />
              </div>
            </Col>
          </Row>
        </AntDHeader>
      </motion.div>
      <Col xs={24} sm={0}>
        <HeaderMenu
          usesFixedMobileView={usesFixedMobileView}
          isLoggedInState={isLoggedInState}
          loadingState={loadingState}
          triggerRedirect={triggerRedirect}
        />
      </Col>
      <motion.div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
          transition: 'all .05s ease',
          translateY: translateYup, // Animate translateY based on scroll
        }}
      >
        <AntDHeader
          id={'fixed-header'}
          className={
            headerExpandClass +
            ' header ' +
            (useMaxWidth ? ' main_container_padding ' : '') +
            (hideOnMobile ? ' header-md ' : '')
          }
          // ref={WrapperRef}
        >
          <Row
            justify='space-between'
            className={`desktop-header ${
              useMaxWidth ? 'main_container_padding__content ' : ''
            }`}
          >
            <Col
              // id='sb-header-logo-col'
              className='gutter-row sb-align-left header-links'
              xs={0}
              sm={12}
              lg={17}
              xl={14}
            >
              <div className='row-center justify-between'>
                <div
                  className='cp row-center'
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  <img className='mg-right-15' src={roundedLogo} />
                  <div className='gSemiBold sb-blue fs-18 row-center'>
                    swim<span className='sb-black'>booker</span>
                  </div>
                </div>
                <Link className='fs-18 gMedium cp sb-black' to={'/search'}>
                  Find Venues
                </Link>
                <Link className='fs-18 gMedium cp sb-black' to={'/about'}>
                  About Us
                </Link>
                <Link className='fs-18 gMedium cp sb-black' to={'/contact'}>
                  Contact Us
                </Link>

                <Link className='fs-18 gMedium cp sb-black' to={'/articles'}>
                  News
                </Link>

                <Link className='fs-18 gMedium cp sb-black' to={'/admin/login'}>
                  Fishery Login
                </Link>
              </div>
            </Col>

            <Col
              className='gutter-row sb-align-right'
              id='sb-header-menu-col'
              xs={24}
              sm={12}
              lg={6}
            >
              <div className='row-center justify-end'>
                <Link
                  className='fs-18 gBold sb-blue-600 mg-right-20'
                  to='/swimbooker-fishery-manager'
                >
                  List your lakes
                </Link>
                <HeaderMenu
                  usesFixedMobileView={usesFixedMobileView}
                  isLoggedInState={isLoggedInState}
                  loadingState={loadingState}
                  triggerRedirect={triggerRedirect}
                />
              </div>
            </Col>
          </Row>
        </AntDHeader>
      </motion.div>
    </>
  ) : (
    <AntDHeader
      id={'fixed-header'}
      className={
        headerExpandClass +
        ' header ' +
        (useMaxWidth ? 'main_container_padding ' : '') +
        (hideOnMobile ? 'header-md ' : '') +
        (isUserProfile && 'custom-header-styles')
      }
    >
      <Row
        justify='space-between'
        className={useMaxWidth ? 'main_container_padding__content ' : ''}
      >
        <Col
          id='sb-header-logo-col'
          className='gutter-row sb-align-left'
          xs={0}
          sm={6}
          md={7}
          lg={9}
        >
          <Link to={'/'}>
            {useLogo ? (
              <img
                alt='swimbooker logo'
                id='home-logo'
                src={
                  process.env.PUBLIC_URL +
                  '/assets/Logos/swimbooker_logo_no_icon.png'
                }
              />
            ) : (
              <p className='white fs-22 gSemiBold'>swimbooker</p>
            )}
          </Link>
        </Col>
        {!isEventSignUp && (
          <Col
            id={'sb-header-search-wrapper'}
            className='gutter-row '
            xs={12}
            sm={12}
            md={10}
            lg={9}
          >
            <SearchBox />
          </Col>
        )}
        <Col
          className='gutter-row sb-align-right'
          id='sb-header-menu-col'
          xs={6}
          sm={6}
          md={7}
          lg={6}
        >
          <HeaderMenu
            usesFixedMobileView={usesFixedMobileView}
            isLoggedInState={isLoggedInState}
            loadingState={loadingState}
            triggerRedirect={triggerRedirect}
          />
        </Col>
      </Row>
    </AntDHeader>
  );
}

export default Header;
