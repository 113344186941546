import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { EVENT_ADMIN_LOGOUT_ACTION } from '../AppConstants';

import { defaultErrorNotification } from './proApiSlice';

export const axiosBaseQuery =
  ({ baseURL = '' }) =>
  async (
    { url, method, data, params, headers = {}, useDefaultErrorHandler = true },
    { dispatch }
  ) => {
    const {
      Accept = 'application/json',
      'Content-Type': ContentType = 'application/json',
      ...otherHeaders
    } = headers;
    try {
      const result = await axios({
        url: baseURL + url,
        method,
        data,
        params,
        headers: {
          Accept: Accept,
          'Content-Type': ContentType,
          ...otherHeaders,
        },
        withCredentials: true,
      });
      if (result.status === 200) {
        return { data: result.data?.result ?? result.data };
      } else {
        return {
          error: {
            status: data.status,
            data: data,
          },
        };
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (useDefaultErrorHandler) {
          defaultErrorNotification(err);
          if (
            err?.response?.status === 401 ||
            (err?.response?.status === 422 &&
              err?.response?.data.detail === 'Signature has expired')
          ) {
            dispatch({
              type: EVENT_ADMIN_LOGOUT_ACTION,
              trigger_logout: true,
            });
          }
        }
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
      if (useDefaultErrorHandler) {
        defaultErrorNotification(err);
      }
      return {
        error: {
          status: 500,
          data: JSON.stringify(err),
        },
      };
    }
  };

export const eventsApiSlice = createApi({
  baseQuery: axiosBaseQuery({
    baseURL: `${process.env.REACT_APP_EVENT_URL}`,
  }),
  reducerPath: 'eventApi',
  tagTypes: [],
  endpoints: () => ({}),
});
