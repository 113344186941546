import { eventsApiSlice } from '../../../../../store/eventApiSlice';

export const extendedApiSlice = eventsApiSlice
  .enhanceEndpoints({ addTagTypes: ['event-admin-auth'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      updateAccountDetails: builder.mutation({
        query: ({ data, id }) => ({
          url: `auth/account_details`,
          method: 'put',
          data,
        }),
      }),
      getAccountDetails: builder.query({
        query: () => ({
          url: 'auth/account_details',
          method: 'get',
        }),
        providesTags: ['auth-account-details'],
      }),
      getAccountDetailsForLogin: builder.query({
        query: () => ({
          url: 'auth/account_details',
          method: 'get',
          useDefaultErrorHandler: false,
        }),
      }),
      getRegions: builder.query({
        query: () => ({
          url: 'compliance/regions',
          method: 'get',
          useDefaultErrorHandler: false,
        }),
      }),
    }),
  });

export const {
  useUpdateAccountDetailsMutation,
  useGetAccountDetailsQuery,
  useGetAccountDetailsForLoginQuery,
  useGetRegionsQuery,
} = extendedApiSlice;
