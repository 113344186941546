import { EVENT_ADMIN_LOGOUT_ACTION } from '../../AppConstants';

const initialState = {
  log_out: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case EVENT_ADMIN_LOGOUT_ACTION:
      return {
        ...state,
        log_out: action.trigger_logout,
      };

    default:
      return state;
  }
}

export default reducer;
