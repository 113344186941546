import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AdminLoginLayout from './containers/admin/LoginLayout';
import Login from './components/admin/components/login';
import MvpLogin from './components/MvpAdmin/Login';
import { useCheckAdminInWebview } from './utils/hooks';
import EventLogin from './components/AnglingTrust/admin/components/compliance/login';
import { useFlags } from 'flagsmith/react';

function AdminLoginRouter() {
  useCheckAdminInWebview();
  const {
    angling_trust_events: { enabled: is_at_events_enabled },
  } = useFlags(['angling_trust_events']);
  return (
    <>
      <AdminLoginLayout>
        <Switch>
          <Route
            exact
            path='/admin/login'
            render={(props) => <Login {...props} />}
          />
        </Switch>
        {is_at_events_enabled && (
          <Switch>
            <Route
              exact
              path='/event_admin/login'
              render={(props) => <EventLogin {...props} />}
            />
          </Switch>
        )}
      </AdminLoginLayout>
      <Switch>
        <Route
          exact
          path='/sb/admin/login'
          render={(props) => <MvpLogin {...props} />}
        />
      </Switch>
    </>
  );
}

export default AdminLoginRouter;
