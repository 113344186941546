import React, { useEffect, useMemo, useState } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';

import '../../assets/css/login.css';
import axios from 'axios';
import { configure } from 'axios-hooks';

import { Helmet } from 'react-helmet';
import { EVENT_ADMIN_LOGOUT_ACTION } from '../../../../../AppConstants';
import { useDispatch } from 'react-redux';

import { createSelector } from '@reduxjs/toolkit';

import { notification } from '../../../../../utils/messaging';
import { useGetAccountDetailsForLoginQuery } from '../infoModal/infoModalApiSlice';

const instance = axios.create({
  withCredentials: true,
  crossDomain: true,
  baseURL: `${process.env.REACT_APP_EVENT_URL}auth/`,
  'Access-Control-Allow-Origin': '*',
  sameSite: null,
  secure: false,
});
const admin_url = '/event_admin';

configure({ instance });

export default function EventLogin(props) {
  const [, setUsername] = useState(null);
  const [forgotPassword, setForgotPassword] = useState(false);
  const isAdminLoggedInSelector = useMemo(() => {
    return createSelector(
      (result) => result.data,
      (userData) => Boolean(userData)
    );
  }, []);
  const { data: accountDetails, isSuccess: loginStatusChecked } =
    useGetAccountDetailsForLoginQuery(undefined, {
      selectFromResult: (result) => ({
        ...result,
        data: isAdminLoggedInSelector(result),
      }),
    });

  useEffect(() => {
    if (loginStatusChecked && accountDetails) {
      props.history.push(`${admin_url}/dashboard`);
    }
  });

  const dispatch = useDispatch();

  // Reset log-out trigger state
  dispatch({
    type: EVENT_ADMIN_LOGOUT_ACTION,
    trigger_logout: false,
  });

  const onFinishLogin = (values) => {
    const formData = new FormData();
    formData.append('username', values.username.trim());
    formData.append('password', values.password);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    instance
      .post('login', formData, config)
      .then((res) => {
        setUsername('');
        props.history.push(`${admin_url}/dashboard`);
      })
      .catch(() => {
        notification.error({
          description: 'Incorrect Details. Please try again.',
        });
      })
      .finally(() => {});
  };

  const onFinishForgotPassword = (values) => {
    let payload_data = { email: values.username };
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    instance
      .post('password-reset/request', payload_data, config)
      .then((res) => {
        notification.success({
          description: res.data.result,
        });
      })
      .catch(() => {
        notification.error({
          description: 'Incorrect Details. Please try again.',
        });
      })
      .finally(() => {});
  };

  const onFinishFailed = () => {};

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  const handleBackLogin = () => {
    setForgotPassword(false);
  };

  return (
    <div className='admin-login-page sb-dark-blue-bg'>
      <Helmet>
        <title>
          Login to Your Event Manager | The Best Fishery Management Software |
          swimbooker
        </title>
      </Helmet>
      <div id='admin-login-form-content'>
        <div className='login-inside-form-content'>
          <Row>
            <Col span={24} className='txt-center mg-bottom-20'>
              {/* <img
                className='mg-right-10'
                width='30'
                alt='admin_logo'
                src={AdminLogo}
              /> */}
              <span className='admin-logo-text gSemiBold'>
                <span className='sb-blue fs-30'>Event</span>{' '}
                <span className='fs-30'>Manager</span>
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className='admin-login-form-wrapper'>
                <div className='admin-login-form-items'>
                  <Form
                    name='basic'
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={
                      forgotPassword ? onFinishForgotPassword : onFinishLogin
                    }
                    onFinishFailed={onFinishFailed}
                  >
                    <p className='dark-swim-blue gSemiBold mg-bottom-5'>
                      Email:
                    </p>
                    <Form.Item
                      name='username'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your username!',
                        },
                      ]}
                    >
                      <Input className='br5 email' />
                    </Form.Item>

                    {forgotPassword ? (
                      <Form.Item>
                        <Button
                          className='admin-login-btn light-green-bg gBold'
                          type='primary'
                          htmlType='submit'
                        >
                          Send Login Link
                        </Button>
                      </Form.Item>
                    ) : (
                      <>
                        <p className='dark-swim-blue gSemiBold mg-bottom-5'>
                          Password:
                        </p>
                        <Form.Item
                          name='password'
                          rules={[
                            {
                              required: true,
                              message: 'Please input your password!',
                            },
                          ]}
                        >
                          <Input.Password className='br5 admin-secret' />
                        </Form.Item>

                        <Form.Item>
                          <Button
                            className='admin-login-btn light-green-bg gBold'
                            type='primary'
                            htmlType='submit'
                          >
                            SIGN IN
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
          {!forgotPassword ? (
            <Row>
              <Col span={24} className='txt-center'>
                <p>
                  <span className='dark-swim-blue gLight'>
                    Forgot your password?{' '}
                  </span>
                  <span
                    className='dark-swim-blue gSemiBold txt-underline forgot-password-link cp'
                    onClick={handleForgotPassword}
                  >
                    Click here
                  </span>
                </p>
              </Col>
            </Row>
          ) : (
            ''
          )}
        </div>
        {forgotPassword ? (
          <Button
            className='back-to-login-btn gBold'
            type='primary'
            onClick={handleBackLogin}
          >
            Back to Login
          </Button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
