import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import sbLogo from '../assets/images/swimbooker_logo.png';
import SBFooter from '../components/common/Footer';
import { matchPath, Redirect, useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import SBHeader from '../components/common/Header';

const { Content } = Layout;

const MainLayout = (props) => {
  const isSignUp = props?.isSignUp;
  const isEventSignUp = props?.isEventSignUp;
  const [showHeader, setShowHeader] = useState(!isSignUp);

  const { pathname } = useLocation();
  const isSearchPage = matchPath(pathname, {
    path: '/search',
    exact: false,
  });
  const noFooter = isSignUp || isEventSignUp;
  const [layoutState, setLayoutState] = useState({
    visible: false,
    bodyOverlay: 'hide',
    headerExpand: 'small',
    menuVisible: false,
    loading: false,
    redirect: null,
  });
  const getLayoutStatePropertySetter = (propName) => (val) =>
    setLayoutState((prevState) => ({
      ...prevState,
      [propName]: typeof val === 'function' ? val(prevState?.[propName]) : val,
    }));

  const closeOverlay = () => {
    setLayoutState((prevState) => ({
      ...prevState,
      bodyOverlay: 'hide',
      headerExpand: 'small',
    }));
  };

  useEffect(() => {
    if (window) {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const paramData = params.get('details');

      if (paramData) {
        const cryptkey = CryptoJS.enc.Utf8.parse(
          process.env.REACT_APP_PRIVATE_KEY
        );
        const cryptiv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV);

        // Decryption
        const crypted = CryptoJS.enc.Base64.parse(
          decodeURIComponent(paramData.toString())
        );
        var decrypt = CryptoJS.AES.decrypt({ ciphertext: crypted }, cryptkey, {
          iv: cryptiv,
          mode: CryptoJS.mode.CTR,
        });
        const decryptedValue = decrypt.toString(CryptoJS.enc.Utf8).split(':');

        if (decryptedValue.length === 2) {
          setShowHeader(false);
        }
      }
    }
  }, []);

  if (layoutState.redirect) {
    return <Redirect to={layoutState.redirect} />;
  }

  return (
    <div>
      {showHeader ? (
        <Layout className='layout _header_main_content'>
          <SBHeader
            useLogo={true}
            isEventSignUp={isEventSignUp}
            headerExpandClass={layoutState?.headerExpand}
            isLoggedInState={[
              layoutState?.isLoggedIn,
              getLayoutStatePropertySetter('isLoggedIn'),
            ]}
            loadingState={[
              layoutState?.loading,
              getLayoutStatePropertySetter('loading'),
            ]}
            triggerRedirect={() =>
              setLayoutState((prevState) => ({
                ...prevState,
                redirect: '/',
              }))
            }
          />
          <Content
            className={`${isSignUp ? 'signup-page main-body' : 'main-body'}`}
          >
            <div
              id='mainbody-overlay'
              className={layoutState.bodyOverlay}
              onClick={closeOverlay}
            />
            <div className='main-container'>{props.children}</div>
          </Content>
        </Layout>
      ) : (
        <>
          {!isSignUp && (
            <div className='center'>
              <img
                src={sbLogo}
                className='mobile-booking-logo'
                style={{
                  width: '220px',
                  height: '55px',
                  margin: '25px 0',
                }}
                alt='Logo'
              />
            </div>
          )}
          <Content
            className={`${isSignUp ? 'signup-page main-body' : 'main-body'}`}
            id='withLogo'
          >
            <div
              id='mainbody-overlay'
              className={layoutState.bodyOverlay}
              onClick={closeOverlay}
            />
            <div className='main-container'>{props.children}</div>
          </Content>
        </>
      )}
      {!noFooter && <SBFooter />}
    </div>
  );
};

export default MainLayout;
